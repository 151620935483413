import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyADGBZ1jg63pKJkjBFYGW0OM61YuPCfASg",
  authDomain: "vertex-auto-solution.firebaseapp.com",
  projectId: "vertex-auto-solution",
  storageBucket: "vertex-auto-solution.appspot.com",
  messagingSenderId: "44845791325",
  appId: "1:44845791325:web:42c5c1aff677037b0cb5b4",
  measurementId: "G-EY31F8798N",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const FirebaseNotifications = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const requestPermission = async () => {
      try {
        const status = await Notification.requestPermission();
        if (status === "granted") {
          const currentToken = await getToken(messaging, {
            vapidKey:
              "BAvA3w__HAbFV2dSH8IbxI5royPtixPyrKMS5OJYPzt3DLwxa3VaJNp26ZU4u6vlxZmCEgnz6or15K_Rrs6p0RQ",
          });
          console.log("FCM Token:", currentToken);
          setToken(currentToken);
        } else {
          console.error("Unable to get permission to notify.");
        }
      } catch (error) {
        console.error("Error getting token:", error);
      }
    };

    requestPermission();

    const handleMessage = (payload) => {
      console.log("Message received. ", payload);
      const { title, body, icon } = payload.notification;
      if (Notification.permission === "granted") {
        new Notification(title, {
          body,
          icon,
        });
      }
    };

    onMessage(messaging, handleMessage);

    // Register the service worker
    const registerServiceWorker = async () => {
      try {
        const registration = await navigator.serviceWorker.register(
          "/firebase-messaging-sw.js"
        );
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
      } catch (error) {
        console.error("Service Worker registration failed:", error);
      }
    };

    registerServiceWorker();
    return () => {
      console.log("Cleanup: useEffect cleanup");
    };
  }, []);

  return (
    <div>
      <h1>Welcome to Firebase Web Notifications</h1>
      {token && <p>Your FCM Token: {token}</p>}
    </div>
  );
};

export default FirebaseNotifications;
